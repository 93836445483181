// Require Bootstrap
require('./bootstrap');

// jQuery ready
$(function() {

    // Smoothscroll links
    $(document).on('click', '[data-action="smoothscroll"]', function(e) {
        e.preventDefault();
        var headerHeight = $('#header').outerHeight();
        var target = $(this).data('target');
        $('html, body').animate({
            scrollTop: ($(target).offset().top - headerHeight)
        }, 500);
    });

    // Detect when a collapsible is triggered and change the class
    $('#section-subscriptions .collapsible-group .collapse').on('show.bs.collapse', function() {
        $(this).closest('.collapsible-group').removeClass('collapsed');
    })

    // Detect when a collapsible is hidden and change the class
    $('#section-subscriptions .collapsible-group .collapse').on('hidden.bs.collapse', function() {
        $(this).closest('.collapsible-group').addClass('collapsed');
    })

    // Contact form submission
    $(document).on('click', '#section-contact form button', function(e) {

        // Prevent default action
        e.preventDefault();

        // Disable the submit button and show the loader icon
        $(this).prop('disabled', true);

        // Define the form
        var form = $(this).closest('form');

        // Show the spinner and hide the contact form
        $('#section-contact .loader:eq(0)').removeClass('d-none');
        $('#contact-ajax-form').html('');

        // Submit the contact form via AJAX
        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: form.serialize(),
            dataType: 'html',
        }).done(function(data) {
            $('#contact-ajax-form').html(data);
            $('#section-contact .loader:eq(0)').addClass('d-none');
        });

    });

});
